import React, { useEffect, useState } from "react"
import "./MobileViewErrorUI.css"
import CloseIcon from "@material-ui/icons/Close"
import { useDispatch, useSelector } from "react-redux"
import {
  activateBanner,
  deactivateBanner,
} from "../../redux/slices/bannerSlice"
import logo from "../../images/drawer_ic.svg"
import menu from "../../images/hamburgerMenu.svg"
import workingWithLaptop from "../../images/working-with-laptop.svg"
import { Menu, MenuItem } from "@material-ui/core"
import { mobileViewMenu } from "../../utils/constants"
import { navigate } from "@reach/router"

function MobileViewErrorUI() {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const isActive = useSelector(state => state.bannerSlice.isActive)

  useEffect(() => {
    function staticBanner(x) {
      if (x.matches) {
        dispatch(activateBanner())
      } else {
        dispatch(deactivateBanner())
      }
    }

    var x = window.matchMedia("(max-width: 600px)")
    staticBanner(x)
    x.addEventListener("change", staticBanner)
  }, [])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (item) => {
    setAnchorEl(null)

    if(item?.to) {
      window && window.open(item.to, "_blank")
    }
  }

  if (isActive) {
    return (
      <div className="mobile-view-ui">
        <div className="mobile-view-menu">
          <img src={logo} />
          <div onClick={handleClick}>
            {!open && <img id="mobile-view-hamburger" src={menu} />}

            {open && (
              <CloseIcon
                fontSize="medium"
                onClick={handleClose}
                className="mobile-view-close-icon"
              />
            )}
          </div>
        </div>
        <div className="mobile-view-message-container">
          <h2 className="mobile-view-upper-h2">
            Sorry, you'll have to try <br /> again on a computer...
          </h2>
          <img src={workingWithLaptop} className="laptop-img" />
          <h2 className="mobile-view-lower-h2">
            We haven't had time to complete building mobile version of our
            venue-website yet.
          </h2>
        </div>
        {open && (
          <div className="mobile-view-menu-overlay" onClick={handleClose} />
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
          }}
          classes={{
            paper: "mobile-view-menu-paper",
          }}
        >
          {mobileViewMenu.map(item => (
            <MenuItem onClick={() => handleClose(item)}>
              <div className="mobile-view-menu-link mobile-view-h3">
                {item.label}
              </div>
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  } else {
    return null
  }
}

export default MobileViewErrorUI
